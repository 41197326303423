.app{
  max-width: 1920px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: Roboto, Arial, sans-serif;
  position: relative;
}

.app-stop-scrol{
  position: fixed;

}