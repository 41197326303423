.sofa-preloader-container {
    display: flex;
    align-items: center;
    justify-content: center;
height: 110px;
}

.sofa-preloader {
    position: relative;
}

.sofa-preloader__base {
    width: 180px;
    height: 50px;
    border: 1px solid #D4171E;
    border-radius: 8px;
    position: absolute;
    top: 100%;
    /* left: 50%; */
    transform: translateX(-50%);
}

.sofa-preloader__cushion,
.sofa-preloader__armrest {
    width: 55px;
    height: 40px;
    border: 1px solid #D4171E;
    border-radius: 8px;
    /* animation: fill-in 1s ease-in-out infinite alternate; */
}

.sofa-preloader__cushion {
    margin-right: 20px;
    position: absolute;
    top: calc(100% - 50px);
}

.cushion-1 {
    /* animation-delay: 0.1s; */
    animation: fill-in 1.1s ease-in-out infinite alternate;
    left: -90px;
}

.cushion-2 {
    animation: fill-in 1.5s ease-in-out infinite alternate;
    left: -28px;
}

.cushion-3 {
    animation: fill-in 2s ease-in-out infinite alternate;
    right: -110px;
}

.sofa-preloader__armrest {
    width: 30px;
    height: 70px;
    animation-delay: 0.4s;
    position: absolute;
    top: calc(100% - 30px);
}

.armrest-1 {

    animation: fill-in 1s ease-in-out infinite alternate;
    left: -128px;
}

.armrest-2 {
    animation: fill-in 1s ease-in-out infinite alternate;
    right: -128px;
}

@keyframes fill-in {
    from {
        transform: translateY(-5px);
    }

    to {
        transform: translateY(5px);
    }
}
