.payment-method {
    display: flex;
    flex-direction: column;
    margin: 32px 0 0;
}

.payment-method__selectors {
    display: flex;
    flex-direction: column;


}

.payment-method__selector {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    margin: 16px 0 0;
}

.payment-method__selector:first-of-type {
    margin: 0;
}

.payment-method__selector-circle {
    width: 32px;
    height: 32px;
    border: 2px solid #D1D1D1;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 0 16px 0 0;
    transition: border-color 0.3s ease-in-out;

}

.payment-method__selector-inside-circle {
    border-radius: 50%;
    background: var(--contrast-color);
    width: 20px;
    height: 20px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.payment-method__selector:hover .payment-method__selector-circle {
    border: 2px solid var(--contrast-color);
}

.payment-method__selector-circle_active {
    border: 2px solid var(--contrast-color);
}

.payment-method__selector-inside-circle_active {
    opacity: 1;
}

.payment-method__selector-text {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 100%;
    /* identical to box height, or 20px */


    color: #000000;
    margin: 0;
}

.payment-method__selector_icactive {
    opacity: 0.3;
}

.payment-method__selector_icactive:hover .payment-method__selector-circle {
    border: 2px solid #D1D1D1 !important;
}



@media (max-width: 700px) {
    .payment-method{
        margin: 20px 0 0;
    }
    .payment-method__selector-text {
        width: calc(100% - 60px);
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 100%;
    }
    .payment-method__selector-circle{
        width: 24px;
        height: 24px;
        margin: 0 8px 0 0;
    }
    .payment-method__selector-inside-circle{
        width: 14px;
        height: 14px;
    }
}
