.favourites {
    padding: 0 60px 110px 60px;
    display: flex;
    flex-direction: column;
}

.favourites__title {
    margin: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 140%;
    /* identical to box height, or 52px */


    color: var(--text-color);

}

.favourites__products {
    margin: 24px 0 0 0;
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    grid-template-rows: max-content;
    grid-auto-rows: max-content;
    row-gap: 20px;
    column-gap: 20px;
    width: 100%;
    align-items: center;
    justify-items: center;
}

.favourites__empty {
    margin: 40px 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.favourites__empty-icon {
    width: 80px;
    height: 80px;
}

.favourites__empty-text {
    font-style: normal;
    font-weight: 600;
    font-size: 50px;
    line-height: 130%;
    /* identical to box height, or 65px */


    /* colour/text/main */

    color: #000000;
    margin: 24px 0 0;
}

.favourites__empty-subtext {
    margin: 24px 0 0;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 130%;
    /* or 18px */


    /* colour/text/secondary */

    color: #686868;

}


.favourites__section-go-back {
    cursor: pointer;
    margin: 24px 0 0;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.favourites__section-go-back-arrow {
    margin: 0 10px 0 0;
}

.favourites__section-go-back-text {
    margin: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 130%;
    /* or 18px */


    color: #000000;

}

@media (max-width: 1505px) {
    .favourites {
        padding: 0 20px 110px 20px;
    }


}


@media (max-width: 1300px) {
    .favourites__products {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
}


@media (max-width: 970px) {
    .favourites__products {
        margin: 12px 0 0;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        row-gap: 12px;
        column-gap: 8px;
    }
}

@media (max-width: 700px) {
    .favourites__title {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 140%;
    }



    .favourites__empty {
        margin: 20px 0 0;
    }

    .favourites__empty-text {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 130%;
    }

    .favourites__empty-subtext {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 130%;
        margin: 18px 0 0;
        text-align: center;
    }

    .favourites__empty-icon {
        width: 60px;
        height: 60px;
    }
}

@media (max-width: 580px) {
    .favourites {
        padding: 0 12px 60px 12px;
    }
}

@media (max-width: 550px) {
    .favourites__products {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}
