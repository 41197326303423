.review-card {
    width: 100%;
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
    background: #F9F9F9;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.review-card__text {
    color: var(--text-color);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    margin: 0;
}

.review-card__footer {
    margin: 24px 0 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

.review-card__user {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.review-card__user-img {

    width: 40px;
    height: 40px;
    border-radius: 50%;

    overflow: hidden;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    mask-image: -webkit-radial-gradient(white, black);
}

.review-card__user-info {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.review-card__user-name {
    color: var(--text-color);
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
}

.review-card__stars {
    display: flex;
    flex-direction: row;
    gap: 4px;
}

.review-card__date {
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: var(--text-color);
}
