.sub-category {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0 60px 110px 60px;
  box-sizing: border-box;
  width: 100%;
}



.sub-category__products-length {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  /* identical to box height, or 21px */


  /* primary/500 */

  color: var(--contrast-color);
  margin: 24px 0 0;

}

.sub-category__name {
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 140%;
  /* identical to box height, or 56px */
  /* colour/text/main */
  color: #000000;
  margin: 0 0 0;
}

.sub-category__products-and-filters {
  position: relative;
  display: flex;
  flex-direction: row;
  margin: 40px 0 0;
}

.sub-category__products-with-btn {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.sub-category__price-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 0;
  width: 185px;
  background: rgba(156, 156, 156, 0.12);
  border-radius: 8px;
  margin: 0 0 0 auto;
  cursor: pointer;
}


.sub-category__price-btn-icon {
  transition: transform 0.3s ease-in-out;
  margin: 0 10px 0 0;
}

.sub-category__price-btn-icon_decrease {
  transform: scale(-1, -1);
}

.sub-category__price-btn-text {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  /* identical to box height, or 21px */


  /* colour/text/main */

  color: #000000;
  margin: 0;

}

.sub-category__products {
  margin: 20px 0 120px 0;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-template-rows: max-content;
  grid-auto-rows: max-content;
  row-gap: 20px;
  column-gap: 20px;
  width: 100%;
  align-items: center;
  justify-items: center;

}

.sub-category__filters {
  position: sticky;
  top: 40px;
  height: fit-content;
  padding: 0 0 40px 0;
  width: 257px;
  margin: 0 24px 0 0;
}

.sub-category__btns {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.sub-category__filters-btn {
  flex-direction: row;
  align-items: center;
  background: rgba(156, 156, 156, 0.12);
  border-radius: 8px;
  padding: 10px;
  width: fit-content;
  display: none;
}

.sub-category__filters-btn-icon {
  margin: 0 8px 0 0;
}

.sub-category__filters-btn-text {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  /* identical to box height, or 21px */


  /* colour/text/main */

  color: #000000;
  margin: 0 0 0 0;
}

.sub-category__filters-btn-count {
  background: var(--contrast-color);
  border-radius: 8px;
  padding: 8px;
  margin: 0 0 0 12px;
}

.sub-category__filters-btn-count-text {
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  /* identical to box height, or 12px */


  color: #FFFFFF;


}

.sub-category__no-products-text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  /* identical to box height, or 21px */


  /* colour/text/secondary */

  color: #686868;

  margin: 24px 0 0;
}

.sub-category__item-preloader {
  width: 100%;
  height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.sub-category__preloader {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 70vh;
  align-items: center;
  justify-content: center;
}

.sub-category__load-more {
  flex-direction: row;
  align-items: center;
  background: rgba(156, 156, 156, 0.12);
  border-radius: 8px;
  /* padding: 10px; */



  cursor: pointer;
  /* border: 1px solid #D4171E; */
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 20px 24px;
  width: 160px;


  transition: background-color 0.3s ease-in-out;
  margin: 0 auto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  /* identical to box height, or 20px */


  /* primary/500 */

  color: #000000;
  transition: color 0.3s ease-in-out;
}






@media (max-width: 1505px) {
  .sub-category {
    padding: 0 20px 110px 20px;
  }
}

@media (max-width: 1300px) {
  .sub-category__products {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media (max-width: 1100px) {
  .sub-category__filters {
    display: none;
  }

  .sub-category__filters-btn {
    display: flex;
  }
}

@media (max-width: 700px) {
  .sub-category__products {
    margin: 12px 0 40px 0;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    row-gap: 12px;
    column-gap: 8px;
  }

  .sub-category__products-length {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    margin: 12px 0 0;
  }

  .sub-category__name {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
  }

  .sub-category__products-and-filters {
    margin: 20px 0 0;
  }

  .sub-category__btns {
    display: grid;
    grid-template-columns: 0.5fr 0.5fr;
    grid-template-rows: 48px;
    grid-auto-rows: 48px;
    row-gap: 12px;
    column-gap: 8px;
  }

  .sub-category__filters-btn {
    padding: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
  }

  .sub-category__filters-btn-icon {
    margin: 0 4px 0 0;
  }

  .sub-category__filters-btn-text {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 130%;
  }

  .sub-category__load-more{
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 130%;
    width: 100%;
  }
  .sub-category__price-btn {
    padding: 0;
    width: 100%;
    height: 100%;
  }

  .sub-category__price-btn-icon {
    margin: 0 4px 0 0;
    width: 10.85px;
    height: 14.47px;
  }

  .sub-category__price-btn-text {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 130%;
  }
}

@media (max-width: 580px) {
  .sub-category {
    padding: 0 12px 50px 12px;
  }
}


@media (max-width: 500px) {
  .sub-category__products {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}


@media (max-width: 350px) {
  .sub-category__products {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
