.cart-page {
  padding: 0 60px 0 60px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  position: relative;
  margin: 0 0 161px 0;
}

.cart-page_step-1{
  padding: 0 60px 0 120px;
  max-width: 1300px;
  width: 100%;
  margin: 0 auto 161px auto;
}

.cart-page__main {
  display: flex;
  flex-direction: column;
  margin: 0 94px 0 152px;

  width: 100%;
  max-width: 815px;
}

.cart-page__section-heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 24px 0 0;
}

.cart-page__section-heading:first-of-type {
  margin: 0;
}

.cart-page__section-heading-numeral {
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 140%;
  /* or 57px */


  color: #000000;
  margin: 0 12px 0 0;
}

.cart-page__section-heading-title {
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 140%;
  /* or 57px */


  color: #000000;
  margin: 0 48px 0 0;
}

.cart-page__section-reset-cart {
  margin: 0 0 0 auto;
  /* border: 2px solid var(--contrast-color); */
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 0;
  cursor: pointer;
}

.cart-page__section-reset-cart-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  /* identical to box height, or 16px */


  /* primary/500 */

  color: var(--contrast-color);
  margin: 0;
}

.cart-page__section-go-back {
  cursor: pointer;
  margin: 0 0 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cart-page__section-go-back-arrow {
  margin: 0 10px 0 0;
}

.cart-page__section-go-back-text {
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  /* or 18px */


  color: #000000;

}



.cart-page__summary {
  position: sticky;
  display: flex;
  flex-direction: column;
  top: 40px;
  height: fit-content;
  padding: 0 0 0 0;
  width: 353px;
  min-width: 353px;
  max-width: 353px;
  margin: 0 0 0 0;
}


.cart-page__summary-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;
  border: 1px solid var(--contrast-color);
  box-sizing: border-box;
  border-radius: 20px;
}

.cart-page__summary-title {
  margin: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 130%;
  /* identical to box height, or 42px */
  color: #000000;
}

.cart-page__summary-count {
  margin: 12px 0 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 130%;
  /* identical to box height, or 26px */


  color: #4B4B4B;
}

.cart-page__summary-total {
  margin: 25px 0 0;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 130%;
  /* identical to box height, or 42px */



  color: var(--contrast-color);

}

.cart-page__summary-delivery {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  /* identical to box height, or 21px */


  color: #000000;
  margin: 24px 0 0;
}

.cart-page__summary-delivery-method {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  /* identical to box height, or 21px */

  text-decoration-line: underline;

  /* primary/500 */

  color: var(--contrast-color);

}

.cart-page__summary-delivery-adress {
  margin: 12px 0 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 130%;
  /* or 21px */


  /* colour/text/tetriary */

  color: rgba(18, 18, 18, 0.56);

}

.cart-page__summary-date {
  margin: 24px 0 0;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  /* identical to box height, or 21px */


  color: #000000;
}

.cart-page__summary-date-period {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  /* identical to box height, or 21px */

  text-decoration-line: underline;

  /* primary/500 */

  color: var(--contrast-color);
}

.cart-page__summary-payment {
  margin: 24px 0 0;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  /* or 21px */


  color: #000000;

}

.cart-page__summary-payment-method {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  /* or 21px */

  text-decoration-line: underline;

  /* primary/500 */

  color: var(--contrast-color);
}

.cart-page__summary-pay-btn {
  padding: 20px 0;
  margin: 24px 0 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: var(--contrast-color);
  transition: opacity 0.3s ease-in-out;
  width: 100%;
  border-radius: 8px;
  cursor: pointer;
}

.cart-page__summary-pay-btn_inactive {
  opacity: 0.3;
  cursor: no-drop;
  pointer-events: none;
}

.cart-page__summary-pay-btn-text {
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  /* identical to box height, or 20px */


  color: #FFFFFF;
}

.cart-page__summary-share {
  margin: 20px 0 0;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */

  text-align: center;

  /* primary/500 */

  color: var(--contrast-color);
  cursor: pointer;
}

.cart-page__empty {
  margin: 57px 0 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cart-page__empty-icon {
  width: 80px;
  height: 80px;
}

.cart-page__empty-title {
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 130%;
  /* identical to box height, or 65px */


  /* colour/text/main */

  color: #000000;
  margin: 24px 0 0;
}

.cart-page__empty-text {
  margin: 24px 0 0;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  /* or 18px */


  /* colour/text/secondary */

  color: #686868;

}

.cart-page__section-go-back_empty {
  margin: 30px 0 0;
}

@media (max-width: 1514px) {
  .cart-page__main {
    margin: 0 94px 0 0;
    width: 100%;
  }

  .cart-page_step-1 .cart-page__main{
    width: fit-content;
  }

}


@media (max-width: 1505px) {
  .cart-page {
    padding: 0 20px 0 20px;
  }

  .cart-page_step-1 {
    width: fit-content;
  }
}


@media (max-width: 1166px) {
  .cart-page {
    flex-direction: column;
  }

  .cart-page__main {
    display: flex;
    flex-direction: column;
    margin: 0;
  }

  .cart-page__summary {
    position: relative;
    top: unset;
    width: 100%;
    min-width: unset;
    max-width: unset;
    margin: 48px 0 0;
  }

  .cart-page__section-heading-numeral {
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 130%;
  }

  .cart-page__section-heading-title {
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 130%;
  }

  .cart-page_step-1 {
    width: 100%;
  }

  .cart-page_step-1 .cart-page__main{
    width: 100%;
  }
}



@media (max-width: 580px) {
  .cart-page {
    padding: 0 12px 0 12px;
  }
}

@media (max-width: 700px) {
  .cart-page__empty {
    margin: 15px 0 0;

  }

  /* .cart-page__section-reset-cart {
    display: none;
  } */
/*
  .cart-page__section-go-back {
    display: none;
  } */

  .cart-page__section-reset-cart{

  }
  .cart-page__section-reset-cart-text{
    font-size: 14px;
  }
  .cart-page__section-go-back_empty {
    display: flex;
  }

  .cart-page__empty-title {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 130%;
  }

  .cart-page__empty-text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 130%;
    margin: 18px 0 0;
    text-align: center;
  }

  .cart-page__section-heading-numeral {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    margin: 0 8px 0 0;
  }

  .cart-page__section-heading-title {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    margin: 0;
  }

  .cart-page__section-heading {
    margin: 12px 0 0;
  }

  .cart-page__summary-info {
    padding: 12px 12px 20px 12px;
  }

  .cart-page__summary-title {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 130%;
  }

  .cart-page__summary-count {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 130%;
  }

  .cart-page__summary-total {
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 130%;
    margin: 25px 0 0;
  }

  .cart-page__summary-delivery {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 130%;
  }

  .cart-page__summary-delivery-adress {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 130%;
  }

  .cart-page__summary-date {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 130%;
  }

  .cart-page__summary-date-period {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 130%;
  }

  .cart-page__summary-payment {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 130%;
  }

  .cart-page__summary-payment-method {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 130%;
  }

  .cart-page__summary-pay-btn {
    padding: 12px 0;
  }

  .cart-page__summary-pay-btn-text {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 100%;
  }

  .cart-page {
    margin: 0 0 80px 0;
  }
}
