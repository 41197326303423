.crumbs {
  margin: 12px 0 0;
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.crumbs__link-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 6px 0 0;
}
.crumbs__link-container:last-of-type{
  margin: 0;
}

.crumbs__link {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 130%;
  /* identical to box height, or 21px */
  /* colour/text/secondary */
  color: #323232;
  text-decoration: none;
  margin: 0 6px 0 0;
  transition: color 0.3s ease-in-out;
  width: max-content;
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.crumbs__link-separetor {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  /* or 18px */
  /* colour/text/tetriary */
  color: rgba(18, 18, 18, 0.56);
  margin: 0;
}

.crumbs__link-container:last-of-type .crumbs__link-separetor{
  display: none;
}

.crumbs__link-container:last-of-type .crumbs__link{
  margin: 0;
  /* color: var(--contrast-color); */
}

@media (max-width: 700px) {
  .crumbs{
    display: none;
  }
}
