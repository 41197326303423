.reviews {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 80px 0 0;
}


.reviews__title {
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 140%;
    margin: 0;
}


.reviews__cards {
    margin: 24px 0 0;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-template-rows: max-content;
    grid-auto-rows: max-content;
    column-gap: 20px;
    row-gap: 20px;
}


@media (max-width: 1260px) {
    .reviews__cards {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    .reviews__title{
        font-size: 24px;
    }
}

@media (max-width: 650px) {
    .reviews__cards {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        column-gap: 12px;
        row-gap: 12px;
    }
    .reviews__title{
        font-size: 20px;
    }
}
