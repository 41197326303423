.account {
    display: flex;
    flex-direction: column;
}

.account__inputs {
    display: flex;
    flex-direction: column;

}

.account__name-inputs {
    display: flex;
    flex-direction: row;
    width: 100%;
}




.account__input-container {
    display: flex;
    flex-direction: column;
    padding: 12px 16px 8px 16px;
    border: 1px solid #939393;
    transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
    box-sizing: border-box;
    border-radius: 12px;
    width: 269px;

}

.account__input-container-box {
    display: flex;
    flex-direction: column;
    margin: 0 0 0 16px;
    width: min-content;
}

.account__input-container-box:first-of-type {
    margin: 0;
}

.account__input-container_error {
    background: rgba(255, 58, 117, 0.04);
    border: 1px solid #FF3A75;
}

.account__input-container_valid {
    border: 1px solid #32A120;
}

.account__input {
    caret-color: black;
    width: 100%;
    background-color: unset;
    font: unset;
    text-rendering: unset;
    letter-spacing: unset;
    word-spacing: unset;
    text-transform: unset;
    text-indent: unset;
    text-shadow: unset;
    display: unset;
    text-align: unset;
    appearance: unset;
    font-style: normal;
    font-weight: normal;
    padding: 0;
    font-size: 20px;
    line-height: 130%;
    /* identical to box height, or 26px */
    border: none;
    outline: none;
    color: #000000;
    resize: none;
}

.account__input:focus {
    caret-color: black;
    outline: none;
}


.account__input::placeholder {


    /* identical to box height, or 26px */


    color: #939393;

}


.account__input-title {
    margin: 6px 0 0;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 130%;
    /* identical to box height, or 21px */


    color: #939393;
}

.account__input-reqiered {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 130%;
    /* identical to box height, or 21px */


    /* warning/500 */

    color: #FF3A75;


}


.account__contact-inputs {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 22px 0 0;
}

.account__input-error {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 130%;
    /* identical to box height, or 21px */


    /* warning/500 */

    color: #FF3A75;
    margin: 8px 0 0;
}

.account__btns {
    display: flex;
    flex-direction: row;
    margin: 28px 0 0;
}

.account__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 28px;

    border-radius: 40px;
    width: fit-content;

    cursor: pointer;
    transition: opacity 0.3s ease-in-out;
}

.account__btn_submit{
    background: var(--contrast-color);
    margin: 0 20px 0 0;
}

.account__btn_exit {
    border: 2px solid var(--contrast-color);
    background: transparent;
}

.account__btn_submit-inactive {
    opacity: 0.3;
    cursor: no-drop;
}

.account__btn-text {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 100%;
    /* identical to box height, or 20px */



    margin: 0;
}

.account__btn-text_submit{
    color: #FFFFFF;
}
.account__btn-text_exit{
    color: var(--contrast-color);
}

@media (max-width: 700px) {
    /* .account {
        margin: 20px 0 0;
    } */

    .account__name-inputs {
        display: flex;
        flex-direction: column;
        width: 100%;

    }

    .account__contact-inputs {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 22px 0 0;
    }

    .account__input-container-box {
        margin: 20px 0 0;
        width: 100%;
    }

    .account__input-container {
        width: 100%;
    }

    .account__login-btn {
        padding: 12px 0;
        width: 100%;
    }

    .account__login-btn-text {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 100%;
    }

    .account__input {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 130%;
    }

    .account__input::placeholder {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 130%;
    }

    .account__input-title {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 130%;
    }

    .account__btns {
        display: flex;
        flex-direction: column;
        margin: 20px 0 0;
    }

    .account__btn {

        padding: 12px 0;

        width: 100%;


    }
    .account__btn_submit{
        margin: 0;
    }
    .account__btn_exit{
        margin: 12px 0 0;
    }

    .account__btn-text {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 100%;
    }
}
