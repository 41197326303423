@import url(./assets/normalize.css);
@import url(./assets/fonts/stylesheet.css);



:root {
    --text-color: #000000;
    --contrast-color: #E11C23;
    --preloader-first-color: #E5EEFC;
    --preloader-second-color: #D6DEFF;
}






h1, h2, h3, h4, h5, h6, blockquote, span {
    padding: 0;
    margin: 0;
    color: var(--text-color);
}

a {
    text-decoration: none;
    color: var(--text-color);
    outline: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}


button {
    padding: 0;
    margin: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}



.dislpay_none {
    display: none;
}

.image_preloader {

    background: linear-gradient(to right, var(--preloader-first-color) 8%, var(--preloader-second-color) 38%, var(--preloader-first-color) 54%);
    background-size: 100vw 100vh;
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;

    height: 100%;
    width: 100%;
    position: relative;

}
