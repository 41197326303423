.cart {
    display: flex;
    flex-direction: column;
    margin: 32px 0 0;
}


.cart__items {
    display: flex;
    flex-direction: column;
    margin: 32px 0 0;
    width: 100%;
    max-width: 100%;
    height: 100%;
}

/*
  .cart__items::-webkit-scrollbar-track {
    border: unset;
    padding: 2px 0;
    background: #EAEAEA;
    border-radius: 8px;
  }

  .cart__items::-webkit-scrollbar {
    width: 4px;
  }

  .cart__items::-webkit-scrollbar-thumb {
    border-radius: 8px;

    background-color: var(--contrast-color);
    border: none;
  }
   */
.cart__item {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-bottom: 32px;
    padding-top: 32px;
    border-bottom: #EEEEEE 1px solid;
}

.cart__item:first-of-type {
    padding-top: 0;
}



.cart__item:last-of-type {
    border-bottom: none;

}

.cart__item_pc:last-of-type {
    border-bottom: none;
}

.cart__item-img {
    width: 100px;
    height: 100px;
    margin: 0 20px 0 0;

}

.cart__item-img-photo {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
}

.cart__item-column {
    display: flex;
    flex-direction: column;
    width: calc(100% - 100px - 20px);
}

.cart__item-row {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.cart__article {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 130%;
    /* or 18px */


    color: #4B4B4B;
    margin: 0;
}

.cart__item-row_first {
    margin: 12px 0 0;
    align-items: flex-start;
}

.cart__item-row_second {
    margin: 16px 0 0;
    align-items: flex-start;
}

.cart__name {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 150%;
    /* or 30px */


    color: #000000;
    margin: 0 70px 0 0;
    max-width: 100%;
}

.cart__count-handler {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 33px 0 auto;
}

.cart__count-number {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 150%;
    /* identical to box height, or 25px */
    margin: 0 16px 0 0;
    text-align: center;
    width: 28px;
    color: #000000;
}

.cart__count-less {
    margin: 0 16px 0 0;
    width: 15px;
    height: 15px;
    transition: opacity 0.3s ease-in-out;
    cursor: pointer;
}

.cart__count-less_inactive {
    opacity: 0.4;
    cursor: no-drop;
}

.cart__count-more {
    width: 15px;
    height: 15px;
    cursor: pointer;
    display: block;
}

/* .cart__count-icon{
    width: 15px !important;
    height: 15px !important;
    display: block;
  } */

.cart__price {

    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 150%;
    /* identical to box height, or 30px */
    word-wrap: none;
    white-space: nowrap;
    width: 89px;
    text-align: right;
    color: #000000;
    margin: 0;
}

.cart__take-from-showroom {
    margin: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 130%;
    /* or 18px */


    color: #4B4B4B;

}

.cart__take-from-showroom_span {
    color: var(--contrast-color);
    text-decoration: none;
}

.cart__icons {
    margin: 0 0 0 auto;
}

.cart__icon-like {
    margin: 0 16px 0 0;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.cart__icon-like-bg {
    fill: transparent;
    transition: fill 0.3s ease-in-out;
}

.cart__icon-like-stroke {
    fill: #121212;
    transition: fill 0.3s ease-in-out;
}

.cart__icon-like-container:hover .cart__icon-like-stroke {
    fill: var(--contrast-color);
}

.cart__icon-like-bg_active {
    fill: var(--contrast-color);
}

.cart__icon-like-stroke_active {
    fill: var(--contrast-color);
}

.cart__icon-trash {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.cart__lower-btns {
    /* position: absolute;
    z-index: 2000;
    bottom: 0;
    left: 0;
    background: #FFFFFF;
    box-shadow: 0px -14px 40px rgba(0, 0, 0, 0.16);
    width: 100%;
    height: 40%; */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto 0 22px;
}

.cart__amount {
    margin: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 100%;
    /* identical to box height, or 20px */


    color: #000000;

}

.cart__order-btn {
    text-decoration: none;
    width: 314px;
    padding: 20px 0;
    background: var(--contrast-color);
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 28px 0 0;
    cursor: pointer;
}

.cart__order-btn-text {
    text-decoration: none;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 100%;
    /* identical to box height, or 20px */


    color: #FFFFFF;
    margin: 0;
}

.cart__go-back-btn {
    width: 314px;
    padding: 20px 0;
    border: 2px solid var(--contrast-color);
    box-sizing: border-box;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 12px 0 0;
    cursor: pointer;
}

.cart__go-back-btn-text {
    margin: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 100%;
    /* identical to box height, or 20px */


    /* primary/500 */

    color: var(--contrast-color);

}

.cart__item_mobile {
    display: none;
}

@media (max-width: 700px) {
    /* .cart__container {
      width: 100%;
      right: -100%;
    }

    .cart__container_active {
      transform: translateX(100%);
    } */

}

@media (max-width: 700px) {


    .cart {
        margin: 16px 0 0;
    }

    .cart__item_mobile {
        display: flex;
        flex-direction: column;
    }

    .cart__item_pc {
        display: none;
    }

    .cart__item-img {
        width: 128px !important;
        height: 93px !important;
        margin: 0 16px 0 0;
    }

    .cart__item-column {
        display: flex;
        flex-direction: column;
        width: calc(100% - 128px - 16px);
    }

    .cart__item-column_first {
        align-items: center;
        margin: 0 16px 0 0;
    }

    .cart__count-handler {
        margin: 0 0 0 17px;
    }

    .cart__icons {
        margin: 0;
    }

    .cart__article {
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 130%;
        margin: 12px 0 0;
        display: none;
    }

    .cart__name {
        margin: 4px 0 0;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 130%;
    }

    .cart__item-row_first {
        margin: 0;
    }

    .cart__item-row_second {
        margin: 23px 0 0;
    }

    .cart__price {
        margin: 0 0 0 auto;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 100%;
    }

    .cart__item {
        padding-bottom: 44px;
        padding-top: 16px;
    }


    .cart__take-from-showroom {
        margin: 4px 0 0;
        font-size: 12px;
    }



}

@media (max-width: 457px) {


    .cart__close-and-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 44px 12px 0 12px;
        max-width: calc(100% - 12px - 12px);
    }

    .cart__items {
        max-width: 100%;
        margin: 0;
    }

    .cart__item {
        padding-bottom: 8px;
        padding-top: 8px;
        width: calc(100% - 12px);
    }

    .cart__items::-webkit-scrollbar {
        width: 2px;
    }


}
