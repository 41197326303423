.requisites {
    padding: 15px 60px 110px 60px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(464px, 1fr));
    grid-template-rows: minmax(min-content, max-content);
    grid-auto-rows: minmax(min-content, max-content);
    row-gap: 16px;
    column-gap: 16px;
    width: calc(100% - 60px * 2);
    align-items: center;
    justify-items: center;
}

.requisites__card {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    box-sizing: border-box;
    border-radius: 16px;
    padding: 25px 20px;

}

.requisites__title {
    margin: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    /* identical to box height, or 21px */


    color: #000000;

}

.requisites__info {
    margin: 24px 0 0;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.requisites__info-text {
    margin: 28px 0 0;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 130%;
    /* or 18px */


    /* colour/text/secondary */

    color: #686868;
}

.requisites__info-text:first-of-type {
    margin: 0;
}

@media (max-width: 700px) {
    .requisites__info {
        margin: 20px 0 0;
    }

    .requisites__info-text {
        margin: 14px 0 0;
        font-size: 12px;
    }

    .requisites {
        grid-template-columns: 1fr;
        grid-template-rows: minmax(min-content, max-content);
        grid-auto-rows: minmax(min-content, max-content);
    }

    .requisites__card {
        padding: 12px;
    }

}

@media (max-width: 1505px) {
    .requisites {
        width: calc(100% - 20px * 2);
        padding: 15px 20px 110px 20px;
    }
}

@media (max-width: 580px) {
    .requisites {
        width: calc(100% - 12px * 2);
        padding: 0 12px 40px 12px;
    }
}
