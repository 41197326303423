.main {
    padding: 44px 58px 80px;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
}


@media (max-width: 1505px) {

    .main {
        padding: 44px 20px 80px;
    }

}


@media (max-width: 750px) {

    .main {
        padding: 10px 20px 80px;
    }

}
