.category-card {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;


}



.category-card__img {
    width: 100%;
    /* height: 200px; */
    object-fit: cover;

    border-radius: 16px;
    overflow: hidden;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    mask-image: -webkit-radial-gradient(white, black);
    aspect-ratio: 2.1 / 1;
}


.category-card__name {
    margin: 10px 0 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: var(--text-color);
}


@media (max-width: 700px) {
    .category-card__name{
        font-size: 14px;
    }
}
