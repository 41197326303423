.search-drop {
    opacity: 0;
    visibility: hidden;
    display: none;
    background: #FBFBFB;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    position: absolute;
    top: calc(100% + 20px);
    left: 0;
    z-index: 10;
    padding: 16px;
    /* min-height: 200px; */
    box-shadow: 3px 3px 15px rgb(0 0 0 / 15%);
    opacity: 0;
    display: none;

}

.search-drop_active {
    display: flex;
    visibility: visible;
    opacity: 1;
    animation: fadein 0.5s;
    /*
    animation-iteration-count: 1; */
}

@keyframes fadein {
    0% {
        transform: translateY(-50px);
        opacity: 0
    }

    100% {
        transform: translateY(0);
        opacity: 1
    }
}




.search-drop__preloader {
    width: 100%;
    /* height: 300px; */
    height: 200px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}


.search-drop__cards {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 24px;
}

.search-drop__no-cards {
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.search-drop__no-cards-text {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
}



/* @media (max-width: 780px) {
    .search-drop{
        width: calc(100% + 20px * 2);
        left: -20px;
    }
} */
