.header {

  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  font-family: Roboto, Arial, sans-serif;

  padding: 0 0 48px;
}

.header__head {
  box-sizing: border-box;
  padding: 20px 60px 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 42px;
  position: relative;
  z-index: 11;
  background-color: #FFFFFF;
}

.header__first-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;



}

.header__location {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.header__location-icon {
  width: 24px;
  height: 24px;
}

.header__location-icon-fill {
  fill: var(--contrast-color);
}

.header__location-name {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 130%;
  color: var(--text-color);
  margin: 0;
}

.header__phone-and-time {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 40px;

}

.header__phone {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 130%;
  color: var(--text-color);
  margin: 0;
}

.header__time {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
}

.header__time-value {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  color: #B3B3B3;
  margin: 0;
}

.header__second-row {
  display: grid;
  grid-template-columns: 70px 1fr max-content;
  grid-template-rows: max-content;
  grid-auto-rows: max-content;
  align-content: space-around;
  justify-content: space-between;
  column-gap: 30px;
  width: 100%;
  z-index: 11;
  position: relative;
}

.header__logo {
  width: 69px;
  height: 60px;
}

.header__logo-fill {
  fill: var(--contrast-color);
}

.header__hendlers {

  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.header__btn-box {
  position: relative;
}

.header__btn {
  border-radius: 1000px;
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  gap: 8px;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  /* identical to box height, or 21px */



}

.header__btn-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  margin: 0;
}

.header__btn_type_catalog {
  background-color: var(--contrast-color);

}

.header__btn_type_catalog .header__btn-text {
  color: #FFFFFF;
}



.header__btn_type_rooms .header__btn-text {
  color: var(--contrast-color);
}

.header__btn-icon {
  width: 20px;
  height: 20px;
}

.header__btn_type_catalog .header__btn-icon-fill {
  fill: #FFFFFF;
}

.header__btn_type_catalog .header__btn-icon-stroke {
  stroke: #FFFFFF;
}

.header__btn_type_rooms .header__btn-icon-fill {
  fill: var(--contrast-color);
}

.header__btn_type_rooms .header__btn-icon-stroke {
  stroke: var(--contrast-color);
}

.header__input-container {
  position: relative;
  width: calc(100% - 400px);

}

.header__input-box {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #FAFAFA;
  border: 2px solid #F4F4F5;
  border-radius: 1000px;
  box-sizing: border-box;
  padding: 16px 16px 16px 24px;
}

.header__input {
  caret-color: black;
  -webkit-appearance: none;
  width: calc(100% - 12px - 24px);
  box-sizing: border-box;
  /* width: calc(100% - 24px - 12px * 2 - 12px); */
  background-color: unset;
  font: unset;
  text-rendering: unset;
  letter-spacing: unset;
  word-spacing: unset;
  text-transform: unset;
  text-indent: unset;
  text-shadow: unset;
  display: unset;
  text-align: unset;
  appearance: unset;
  font-style: normal;
  border: none;
  padding: 0;
  margin-top: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: #000000;
}


.header__input:focus {
  outline: none;
}

.header__input-icon {
  width: 24px;
  height: 24px;
}

.header__input-icon-fill {
  fill: var(--contrast-color);
}

.header__main-links {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
}

.header__main-link {
  display: flex;
  flex-direction: column;
  align-items: center;

}

.header__main-link-icon-and-counter {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 12px 0 0;

}

.header__main-link-icon {
  width: 20px;
  height: 20px;
}

.header__main-link-icon-stroke {
  stroke: var(--text-color);
}

.header__main-link-counter {
  position: absolute;
  bottom: 13px;
  left: 14px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  box-sizing: border-box;
  margin: 0;
  color: #FFFFFF;
  background-color: var(--contrast-color);
  border-radius: 1000px;
  padding: 2px 4px;
  text-align: center;
}

.header__main-link-name {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 130%;
  /* identical to box height, or 17px */

  text-align: center;

  /* colour/text/tetriary */

  color: rgba(18, 18, 18, 0.56);
  margin: 12px 0 0;
}

.header__main-links_mobile {
  display: none;
}

.header__logo-box_mobile {
  display: none;
}

.header__mobile-btns {
  display: none;
}

.header__menu {}

.header__menu-icon {
  width: 34px;
  height: 34px;
}

.header__menu-icon-fill {
  fill: var(--text-color);
}

.header__btn-icon_close-catalog {
  position: absolute;
  z-index: 3;
  opacity: 0;
  background-color: var(--contrast-color);
  transition: all 0.2s ease-in-out;
}

.header__btn-icon_close-catalog-open {
  opacity: 1;
}

.header__btn-icon-rooms-fill {
  fill: var(--contrast-color);
}

.header__btn-icon_close-rooms {
  position: absolute;
  z-index: 3;
  opacity: 0;
  background-color: #FFFFFF;
  transition: all 0.2s ease-in-out;
}

.header__btn-icon_close-rooms-open {
  opacity: 1;
}

@media (max-width: 1505px) {
  .header__head {
    padding: 20px 20px 24px;

  }
}

@media (max-width: 1100px) {
  .header__input-container {
    width: 100%;
  }

  .header__btn-text {
    display: none;
  }

  .header__main-link-name {
    display: none;
  }

  .header__main-link-icon-and-counter {
    margin: 0;
  }

  .header__main-link {
    padding: 0 12px;
    box-sizing: border-box;
  }
}

@media (max-width: 780px) {
  .header__btn_pc {
    display: none;
  }

  .header__main-links_pc {
    display: none;
  }

  .header__logo-box_pc {
    display: none;
  }

  .header__second-row {
    grid-template-columns: 1fr;
  }

  .header__hendlers {
    gap: 0;
  }

  .header__input-box {
    padding: 12px;
  }

  .header__input-icon {
    width: 16px;
    height: 16px;
  }

  .header__phone-and-time {
    display: none;
  }

  .header__location {
    display: none;
  }

  .header__main-links_mobile {
    display: flex;
  }

  .header__logo-box_mobile {
    display: flex;
  }

  .header__logo {
    width: 69px;
    height: 60px;
  }

  .header__main-link-counter {
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 100%;
    padding: 3px 4px;
  }

  .header__main-links {
    gap: 8px;
  }

  .header__mobile-btns {
    display: flex;
    flex-direction: row;
    gap: 25px;
    align-items: center;
  }

  .header__main-link-icon-and-counter {
    margin: 0;
  }

  .header__head {
    gap: 30px;
  }

  .header__input {
    font-size: 14px;
  }
}


/*
@media (max-width: 550px) {
    .header__head {
        padding: 20px 12px 28px;

    }
} */
