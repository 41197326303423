.profile {
    padding: 52px 60px 110px 60px;
    display: flex;
    flex-direction: column;
}

@media (max-width: 435px) {
    .profile__page-seloctors_mobile {
        width: 100%;
    }
}

@media (max-width: 1505px) {
    .profile {
        padding: 52px 20px 110px 20px;
    }
}



.profile__title {
    margin: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 130%;
    /* identical to box height, or 52px */


    color: #000000;

}

.profile__page-seloctors {
    display: flex;
    flex-direction: row;
    margin: 24px 0 0;

}


.profile__page-btn {
    text-decoration: none;
    margin: 0 40px 0 0;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 130%;
    /* identical to box height, or 26px */
    cursor: pointer;
    padding-bottom: 2px;
    transition: border-color 0.3s ease-in-out;
    border-bottom: 2px solid rgba(255, 255, 255, 0);
    /* colour/text/main */

    color: #000000;
}

.profile__page-btn:last-of-type {
    margin: 0;
}

.profile__page-btn_active {
    border-bottom: 2px solid var(--contrast-color);
}

.profile__item {
    margin: 40px 0 0;
}

.profile__page-seloctors_mobile {
    display: none;
}

.profile__page-selected-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.profile__page-selected-row-text {
    margin: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 130%;
    /* identical to box height, or 21px */


    /* colour/text/main */

    color: #000000;


}

.profile__page-selected-row-arrow {
    width: 16px;
    height: 16px;
    transform: rotate(-180deg);
    transition: transform 0.3s ease-in-out;
}

.profile__page-selected-row-arrow_active {
    transform: rotate(0deg);
}

.profile__page-select-dropdown {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 24px 0 0;
}

.profile__page-select-dropdown-item {
    margin: 10px 0 0;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 130%;
    text-decoration: none;
    width: 100%;
    /* identical to box height, or 21px */


    /* colour/text/main */

    color: #000000;
}

.profile__page-select-dropdown-item:first-of-type {
    margin: 0;
}

@media (max-width: 1100px) {
    .profile__page-seloctors {
        margin: 20px 0 0;

    }

    .profile__page-btn {
        margin: 0 20px 0 0;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 130%;
    }
}

@media (max-width: 700px) {
    /* .profile__page-seloctors_pc {
        display: none;
    } */

    /* .profile__page-seloctors_mobile {
        display: flex;
        flex-direction: column;
        padding: 12px 16px;
        border: 2px solid var(--contrast-color);
        box-sizing: border-box;
        border-radius: 8px;
        width: 276px;
    }

    .profile__item {
        margin: 20px 0 0;
    }

    .profile__title {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 130%;

    }
    .profile {
        padding: 12px 20px 110px 20px;
    } */
    .profile {
        padding: 12px 20px 110px 20px;
    }

    .profile__page-btn {
        margin: 0 20px 0 0;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 130%;
        /* identical to box height, or 21px */


        /* colour/text/main */

        color: #000000;
        padding-bottom: 4px;
    }
    .profile__item {
        margin: 20px 0 0;
    }

    .profile__title {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 130%;

    }
}


@media (max-width: 442px) {
    /* .profile__page-seloctors_mobile {

        width: 100%;
    } */
}

@media (max-width: 580px) {
    .profile {
        padding: 12px 12px 20px 12px;
    }
}
