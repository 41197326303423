.product-card {
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  border: 1px solid #F8F8F8;
  box-sizing: border-box;
  border-radius: 16px;
  width: 100%;
  height: 100%;
  text-decoration: none;
  padding: 12px;
  transition: all 0.2s linear;
}

.product-card:hover {
  position: relative;
  z-index: 70;
  box-shadow: 3px 3px 15px rgb(0 0 0 / 15%);
}

.product-card__link {
  text-decoration: none;
}

.product-card__like-container {
  margin: 0 0 0 auto;
  display: block;
  cursor: pointer;
}

.product-card__like {
  width: 24px;
  height: 28px;
}

.product-card__like-bg {
  fill: transparent;
  transition: fill 0.3s ease-in-out;
}

.product-card__like-stroke {
  fill: #121212;
  transition: fill 0.3s ease-in-out;
}

.product-card__like-container:hover .product-card__like-stroke {
  fill: #D4171E;
}

.product-card__like-bg_active {
  fill: #D4171E;
}

.product-card__like-stroke_active {
  fill: #D4171E;
}

.product-card__img-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin: 8px auto 0;

  width: 100%;
  max-width: 450px;
}

.product-card__img {

  width: 100%;
  max-width: 450px;
  /* height: 126px; */
  object-fit: scale-down;
  aspect-ratio: 1.72 / 1;
  position: relative;
  z-index: 1;

  /* width: calc(100% + 24px); */
  /* object-fit: scale-down;
  aspect-ratio: 1 / 1; */
}

.product-card__price {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 24px 0 0;
  height: 24px;
}

.product-card__main-price {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 100%;
  /* identical to box height, or 32px */


  /* colour/text/main */

  color: #000000;
  margin: 0 12px 0 0;
}

.product-card__discount {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 97, 97, 0.32);
  border-radius: 32px;
  width: 66px;
  padding: 8px 0;
  margin: 0 12px 0 0;
}

.product-card__discount-percent {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 100%;
  /* identical to box height, or 20px */


  color: #474747;
  margin: 0;
}

.product-card__last-price {
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 100%;
  /* identical to box height, or 20px */

  text-decoration-line: line-through;

  /* colour/text/secondary */

  color: #686868;

}

.product-card__name {
  margin: 20px 0 0;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  /* identical to box height, or 24px */


  /* colour/text/main */

  color: #000000;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

}

.product-card__amount {
  margin: 12px 0 0;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  /* identical to box height, or 16px */


  /* success/600 */

  color: #27A422;
}

.product-card__amount_zero {
  color: #FF3A75;
}

.product-card__cart-btn {
  cursor: pointer;
  border: 1px solid #D4171E;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  width: 100%;
  margin: 24px 0 0;

  transition: background-color 0.3s ease-in-out;
}

.product-card__cart-btn-text {
  margin: 0 8px 0 0;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  /* identical to box height, or 20px */


  /* primary/500 */

  color: #D4171E;
  transition: color 0.3s ease-in-out;

}

.product-card__cart-btn-icon {
  width: 20px;
  height: 20px;
}

.product-card__cart-btn-icon-container {
  position: relative;
  width: 20px;
  height: 20px;
}

.product-card__cart-btn-icon-path {
  transition: stroke 0.3s ease-in-out;
}

.product-card__cart-btn-icon-stroke {
  stroke: #D4171E;
}

/* .product-card__cart-btn:hover {
  background: #7928BD;
} */

/* .product-card__cart-btn:hover .product-card__cart-btn-text {
  color: #FFFFFF;
}

.product-card__cart-btn:hover .product-card__cart-btn-icon-path {
  stroke: #FFFFFF;
} */

.product-card__cart-btn_selected {
  background: #D4171E;
}

.product-card__cart-btn-icon_tick {
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.2s ease-in-out;
}


.product-card__cart-btn-icon_close {
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.2s ease-in-out;
  opacity: 0;
}


.product-card__cart-btn:hover .product-card__cart-btn-icon_tick {
  opacity: 0;
}

.product-card__cart-btn:hover .product-card__cart-btn-icon_close {
  opacity: 1;
}

.product-card__link {
  display: flex;
  flex-direction: column;
}

/*
@media (max-width: 1700px) {
  .product-card__like {
    width: 32px;
    height: 32px;
  }


  .product-card__img {
    height: 194px;
    margin: 8px auto 0;
    max-width: 350px;
  }

  .product-card {
    padding: 14px;
  }

  .product-card__price {
    margin: 16px 0 0;
    height: 37px;
  }

  .product-card__main-price {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 150%;
  }

  .product-card__discount {
    width: 58px;
  }

  .product-card__discount-percent {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
  }

  .product-card__last-price {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 130%;
  }

  .product-card__amount {
    margin: 6px 0 0;
  }

  .product-card__cart-btn {
    margin: 8px 0 0;
    height: 60px;
  }
} */

.product-card__img-grid {
  display: grid;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  grid-template-rows: 100%;
  column-gap: 2px;
}

.product-card__img-grid-item {
  width: 100%;
  height: 100%;
  transition: all 0.2s linear;
  cursor: pointer;
  box-sizing: border-box;

}




.product-card__img-grid-item-cache {
  visibility: hidden;
  display: none;
}

.product-card__img-sliders {
  display: grid;
  width: 40%;
  position: absolute;
  z-index: 2;
  column-gap: 4px;
  bottom: -8px;
  grid-template-rows: 4px;
}

.product-card__img-slider{
  width: 100%;
  height: 100%;
  background-color: #121212;
  border-radius: 4px;
  opacity: 0.1;
  transition: all 0.2s linear;
}

.product-card__img-slider_active{
  /* background-color: var(--contrast-color); */
  opacity: 0.4;
}


@media (max-width: 700px) {

  /* .product-card__like-container {
    display: none;
  } */

  .product-card__img-grid {
    display: none;
  }
.product-card__img-sliders{
  display: none;
}
  .product-card__like-container {
    width: 20px;
    height: 20px;
  }

  .product-card__like {
    width: 20px;
    height: 20px;
  }

  .product-card__img-box {
    margin: 8px auto 0;
    height: 84px;

    max-width: 170px;
  }

  .product-card__img {
    height: 84px;

    max-width: 170px;
  }

  .product-card {
    padding: 12px 8px;
  }

  .product-card__price {
    margin: 16px 0 0;
    height: 24px;
  }

  .product-card__main-price {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
  }

  .product-card__discount {
    width: unset;
    padding: 4px;
    margin: 0;
  }

  .product-card__discount-percent {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 130%;
  }

  .product-card__last-price {
    display: none;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 130%;
  }

  .product-card__name {
    margin: 16px 0 0;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 150.02%;
  }

  .product-card__amount {
    margin: 6px 0 0;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 150%;
  }

  .product-card__cart-btn {
    margin: 12px 0 0;
    padding: 12px 0;
    border: 1px solid var(--contrast-color);
    height: 40px;
  }

  .product-card__cart-btn-text {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 100%;
  }

  .product-card__cart-btn-icon {
    width: 16px;
    height: 16px;
  }

  .product-card__cart-btn:hover {
    background: unset;
  }

  .product-card__cart-btn:hover .product-card__cart-btn-text {
    color: var(--contrast-color);
  }

  .product-card__cart-btn:hover .product-card__cart-btn-icon-path {
    stroke: var(--contrast-color);
  }

  .product-card__cart-btn_selected {
    background: #D4171E !important;
  }
}
