.category {
  padding: 0 60px 110px 60px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.category__preloader{
  width: 100%;
  height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.category__name {
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 140%;
  /* identical to box height, or 56px */
  /* colour/text/main */
  color: #000000;
  margin: 16px 0 0;
}

.category__sub-categories {
  box-sizing: border-box;
  margin: 16px 0 0;
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  grid-template-rows: max-content;
  grid-auto-rows: max-content;
  row-gap: 20px;
  column-gap: 20px;
  width: 100%;
  align-items: center;
  justify-items: center;
}




@media (max-width: 1505px) {
  .category {
    padding: 0 20px 110px 20px;
  }
}

@media (max-width: 1650px) {
  .category__sub-categories {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@media (max-width: 1500px) {
  .category__sub-categories {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}


@media (max-width: 950px) {
  .category__sub-categories {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}


@media (max-width: 700px) {
  .category__sub-categories {
    grid-template-columns: repeat(1, minmax(0, 1fr));


  }

  .category__name {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
  }


}

@media (max-width: 580px) {
  .category {
    padding: 0 12px 50px 12px;
  }
}
