.refund {
    padding: 52px 60px 110px 60px;
    display: flex;
    flex-direction: column;
}

.refund__title {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 130%;
    /* identical to box height, or 42px */


    /* colour/text/main */

    color: #000000;
    margin: 0;
}

.refund__text {
    margin: 12px 0 0;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 130%;
    /* or 26px */


    /* colour/text/main */

    color: #000000;
    max-width: 740px;
}

.refund__list-items {
    display: flex;
    flex-direction: column;
    margin: 42px 0 0;
}

.refund__list-item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: 24px 0 0;
}

.refund__list-item:first-of-type {
    margin: 0;
}

.refund__list-item-circle {
    margin: 0 8px 0 0;
    width: 16px;
}

.refund__list-item-text {
    margin: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 130%;
    /* or 21px */


    /* colour/text/secondary */

    color: #686868;

    max-width: 700px;
    width: calc(100% - 16px - 8px);
}

@media (max-width: 1100px) {
    .refund__list-item {
        margin: 28px 0 0;
    }
}

@media (max-width: 700px) {
    .refund__title {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 130%;
    }

    .refund__text {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 130%;
    }
    .refund__list-items{
        margin: 20px 0 0;
    }
    .refund__list-item {
        margin: 23px 0 0;
    }
}

@media (max-width: 1505px) {
    .refund {
        padding: 52px 20px 110px 20px;
    }
}

@media (max-width: 580px) {
    .refund {
        padding: 12px 12px 20px 12px;
    }
}
