.recovery {
    padding: 15px 60px 110px 60px;
    display: flex;
    flex-direction: column;
}

.recovery__container {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.recovery__text-container {
    display: flex;
    flex-direction: column;
    max-width: 470px;
    margin: 0 137px 0 0;
}

.recovery__title {
    margin: 0;
    font-style: normal;
    font-weight: bold;
    font-size: 44px;
    line-height: 130%;
    /* or 57px */


    color: #121212;

}

.recovery__text {
    margin: 32px 0 0;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 130%;
    /* or 31px */


    color: #121212;

}

.recovery__form-container {
    display: flex;
    flex-direction: column;
    padding: 36px 20px 20px;
    border: 1px solid var(--contrast-color);
    box-sizing: border-box;
    border-radius: 12px;
    width: 400px;
}

.recovery__form-title {
    margin: 0;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 100%;
    /* identical to box height, or 24px */


    /* colour/text/main */

    color: #000000;

}

.recovery__form-subtitle {
    margin: 20px 0 0;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 130%;
    /* or 26px */


    color: #000000;

}

.recovery__inputs {
    margin: 20px 0 0;
    display: flex;
    flex-direction: column;
}

.recovery__input-container {
    display: flex;
    flex-direction: column;
    padding: 12px 16px 8px 16px;
    border: 1px solid #939393;
    transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
    box-sizing: border-box;
    border-radius: 12px;
    width: 100%;

}

.recovery__input-container-box {
    display: flex;
    flex-direction: column;
    margin: 20px 0 0;
    width: 100%;
}

.recovery__input-container-box:first-of-type {
    margin: 0;
}

.recovery__input-container_error {
    background: rgba(255, 58, 117, 0.04);
    border: 1px solid #FF3A75;
}

.recovery__input-container_valid {
    border: 1px solid #32A120;
}

.recovery__input {
    caret-color: black;
    width: 100%;
    background-color: unset;
    font: unset;
    text-rendering: unset;
    letter-spacing: unset;
    word-spacing: unset;
    text-transform: unset;
    text-indent: unset;
    text-shadow: unset;
    display: unset;
    text-align: unset;
    appearance: unset;
    font-style: normal;
    font-weight: normal;
    padding: 0;
    font-size: 20px;
    line-height: 130%;
    /* identical to box height, or 26px */
    border: none;
    outline: none;
    color: #000000;
    resize: none;
}

.recovery__input:focus {
    caret-color: black;
    outline: none;
}


.recovery__input::placeholder {

    /* identical to box height, or 26px */


    color: #939393;

}


.recovery__input-title {
    margin: 6px 0 0;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 130%;
    /* identical to box height, or 21px */


    color: #939393;
}

.recovery__input-reqiered {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 130%;
    /* identical to box height, or 21px */


    /* warning/500 */

    color: #FF3A75;


}

.recovery__input-error {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 130%;
    /* identical to box height, or 21px */


    /* warning/500 */

    color: #FF3A75;
    margin: 8px 0 0;
}

.recovery__recover-pass {
    margin: 16px 0 0;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 100%;
    /* identical to box height, or 18px */

    text-decoration-line: underline;

    color: #000000;


}


.recovery__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    padding: 20px 0;
    width: 100%;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;

}

.recovery__btn_inactive {
    opacity: 0.3;
    cursor: no-drop;
}



.recovery__btn-text {
    margin: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 100%;
}

.recovery__btn_login {
    background: var(--contrast-color);
    margin: 40px 0 0;
}

.recovery__btn-text_login {
    color: #FFFFFF;

}

.recovery__btn_signup {
    border: 2px solid var(--contrast-color);
    margin: 20px 0 0;
}

.recovery__btn-text_signup {
    color: var(--contrast-color);

}

.recovery__code-timeout {
    margin: 20px 0 0;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 130%;
    /* identical to box height, or 16px */


    /* colour/text/secondary */
    max-width: 310px;
    cursor: pointer;
    color: #686868;
    transition: color 0.3s ease-in-out;
}

.recovery__code-timeout_active {
    color: var(--contrast-color);
}
.recovery__submit-error{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 130%;
    /* identical to box height, or 21px */


    /* warning/500 */
    max-width: 310px;
    text-align: center;
    color: #FF3A75;
    margin: 8px auto 0;
}
@media (max-width: 1000px) {
    .recovery__container {
        flex-direction: column;
        align-items: center;
    }

    .recovery__text-container {
        margin: 0;
        max-width: 400px;
    }

    .recovery__form-container {
        margin: 40px 0 0;
    }

    .recovery__title {
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 130%;
        /* or 42px */

        text-align: center;
    }

    .recovery__text {
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 130%;
        /* or 26px */

        text-align: center;
        margin: 20px 0 0;
    }
}

@media (max-width: 500px) {
    .recovery__form-container {
        padding: 0;
        border: none;
        width: 100%;
    }

    .recovery__title {
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 130%;
        max-width: 236px;
        margin: 0 auto;
    }

    .recovery__text {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 130%;
        max-width: 100%;
    }

    .recovery__form-title {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 100%;
    }

    .recovery__input {
        font-size: 16px;
    }

    .recovery__input-title {
        font-size: 12px;
    }

    .recovery__input-container-box {
        margin: 12px 0 0;
    }

    .recovery__recover-pass {
        margin: 16px 0 0;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 100%;
    }

    .recovery__btn-text {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 100%;
    }

    .recovery__btn {
        padding: 16px 0;
    }

    .recovery__btn_signup {
        margin: 12px 0 0;
    }

    .recovery__form-subtitle {
        margin: 12px 0 0;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 130%;
    }

    .recovery__code-timeout {
        margin: 16px 0 0;
        font-size: 14px;
    }
}

@media (max-width: 1505px) {
    .recovery {

        padding: 15px 20px 110px 20px;
    }
}

@media (max-width: 580px) {
    .recovery {
        padding: 0 12px 40px 12px;
    }
}
