@keyframes main-preloader-inside-white {
    0% {
        -webkit-transform: scale(0, 0);
        -moz-transform: scale(0, 0);
        -ms-transform: scale(0, 0);
        -o-transform: scale(0, 0);
        transform: scale(0, 0);
    }
    100% {
        -webkit-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }
}

@keyframes main-preloader-inside-red {
    0% {
        -webkit-transform: scale(0, 0);
        -moz-transform: scale(0, 0);
        -ms-transform: scale(0, 0);
        -o-transform: scale(0, 0);
        transform: scale(0, 0);
    }
    30% {
        -webkit-transform: scale(0, 0);
        -moz-transform: scale(0, 0);
        -ms-transform: scale(0, 0);
        -o-transform: scale(0, 0);
        transform: scale(0, 0);
    }
    100% {
        -webkit-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }
}

.main-preloader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    z-index: 10;
    padding: 0;
    margin-top: 0;
    align-self: center;
    margin-bottom: 0;
}

.main-preloader__container {
    width: 100px;
    height: 100px;
    position: relative;
}

.main-preloader__round {
    width: 100px;
    height: 100px;
    position: absolute;
    top: 0;
    left: 0;
    background: #FFFFFF;
    border-radius: 50px;
}

.main-preloader__round::after,
.main-preloader__round::before {
    content: '';
    width: 100px;
    height: 100px;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50px;
}

.main-preloader__round::after {
    background: #D5171E;
    animation: main-preloader-inside-white 1s ease-in-out infinite;
}

.main-preloader__round::before {
    z-index: 10;
    background: #FFFFFF;
    animation: main-preloader-inside-red 1s ease-in-out infinite;
}
